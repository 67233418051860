import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import track from "../../components/track"

const PrivacyPolicy = () => {

  const pageTitle = 'Privacy Policy';
  const pageDescription = 'Privacy Policy';
  const pageName = "Privacy Policy";

  return (
    <Layout pageTitle={pageTitle} pageDescription={pageDescription} pageType="patient" pageSection="patient-privacy-policy" pageClass="privacy-policy"  pageName={pageName}>
      <div className="container">
        <h1>Privacy Policy</h1>
        <p><span className="bold">Effective <span className="magenta">September 1, 2023</span></span></p>
        <p>As part of our efforts to facilitate and make your experience with the Valinor Pharma, LLC Website as user-friendly and enjoyable as possible, we may collect and use certain information you provide to us. This Privacy Policy addresses the collection, use, and disclosure of information that Valinor Pharma, LLC may gather during your use of the Website. UNLESS OTHERWISE DEFINED IN THIS PRIVACY POLICY, TERMS USED IN THIS PRIVACY POLICY HAVE THE SAME MEANINGS AS IN THE WEBSITE <Link to="../terms-of-use">TERMS OF USE</Link>.</p>
        <p>Please review the entire policy to learn the types of End User information Valinor Pharma, LLC gathers, how Valinor Pharma, LLC uses that End User information, what End User information is disclosed and to what third parties, and how Valinor Pharma, LLC safeguards your End User information.</p>
        <p>Please also check back periodically as this Privacy Policy may change from time to time. We will notify you of any material changes by publishing the new Privacy Policy on the Website. The End User information Valinor Pharma, LLC gathers is and will be subject to the Privacy Policy in effect at the time the information is gathered. Should you have any questions or concerns at any time, please feel free to contact us.</p>
        <p>This Privacy Policy applies only to the Valinor Pharma, LLC Website. This Privacy Policy does not apply to any websites managed, maintained, and/or hosted by third parties not directly affiliated with Valinor Pharma, LLC that you may visit, e.g., via a link provided through the Website or on a website maintained by any third party. Valinor Pharma, LLC does not control the privacy policies of other websites to which we may provide hyperlinks. We encourage you to learn more about the online privacy and data security policies of third party websites directly from such third parties, as their policies may differ from ours.</p>
        <p>By submitting your information to us and by using the Website, Valinor Pharma, LLC will assume that you have read, understood, and consent to the Agreement, which includes this Privacy Policy, the Website <Link to="../terms-of-use">Terms of Use</Link>, and all other applicable Valinor Pharma, LLC rules and policies. We also assume that you consent to Valinor Pharma, LLC’s use of your information as described in the Agreement. If you do not consent, you should not access or use the Website.</p>
        <p><span className="bold">1. Information Valinor Pharma, LLC Collects</span></p>
        <p><span className="bold">a) Personal Information and Non-Personal Information</span></p>
        <p><em>“Personal Information”</em> is information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked to (directly or indirectly) an individual person or household. Personal Information includes, but is not limited to, your full name, email address, and any other information that could be used to personally identify you. We may ask you for Personal Information if, for example, you contact us through the Website.</p>
        <p>We also collect non-Personal Information about you. Non-Personal Information includes, but is not limited to, your zip code (on its own) and individual preferences. Certain non-Personal Information would be considered a part of your Personal Information if it were combined with other identifiers (for example, combining your zip code with your street address) in a way that enables you to be identified. But the same pieces of information are considered non-Personal Information when they are taken alone or combined only with other non-Personal Information (for example, your gender only or your viewing preferences).</p>
        <p>We use your Personal Information (in some cases, in conjunction with your non-Personal Information) mainly to respond to communications from you. We may also use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. If you decide at any time that you no longer wish to receive such communications from us, please follow the unsubscribe instructions provided in any of the communications or update your account information.</p>
        <p>We may also combine your Personal Information with non-Personal Information and aggregate it with information collected from other users to attempt to provide you with a better experience, to improve the quality and value of the Website, and to analyze and understand how the Website is used.</p>
        <p><span className="bold">b) Usage Data</span></p>
        <p>When you visit the Website, whether you are logged in or just browsing without logging in, our servers automatically record information that your browser sends whenever you visit a website. The information sent automatically by your browser is referred to as “Usage Data.” This Usage Data may include information such as the manufacturer and model of your device; your Internet Service Provider (ISP); your device’s Internet Protocol (“IP”) address (or other device identifier), browser type, and operating system; referring/exit pages; clickstream data; Website access times and dates, pages of the Website that you visit, the time spent on those pages, and information you search for on the Website; and other statistics.</p>
        <p>We use this information for technical administration of the Website, including to monitor and analyze use of the Website, to increase the functionality and user-friendliness of the Website, and to better tailor the Website to End Users’ needs.</p>
        <p>Usage Data may be Personal Information or non-Personal Information. Whenever we associate Usage Data with Personal Information, we will treat it as Personal Information.</p>
        <p><span className="bold">2. Collection of Information</span></p>
        <p>Valinor Pharma, LLC may use a number of different techniques and technologies to collect Personal Information, non-Personal Information, and Usage Data. For example, Valinor Pharma, LLC may use first-party cookies, Google Analytics services, log files, clear gifs, pixel tags, embedded scripts, and/or other technology to monitor the use of the Website.</p>
        <p><span className="bold">(a) Forms</span></p>
        <p>We request certain information from you through the use of forms when you send us correspondence through the Website.</p>
        <p><span className="bold">(b) Cookies</span></p>
        <p>As is regular practice on many websites, the Website uses first-party cookies and other technologies to help us understand which parts of the Website are more popular and how much time users spend there. A cookie is a small amount of data that is sent to and stored on your device from our server. Cookies may, for example, allow a user to enter a password less frequently or remember a user’s preferences during an online session.</p>
        <p>We use Google Analytics to help analyze how End Users use our services. Google Analytics uses cookies to collect information such as how often users visit our Website, the pages they visit, and what other sites they used prior to coming to our Website. For more information on Google Analytics, visit <a target="_blank" href="https://policies.google.com/technologies/partner-sites">Google’s Privacy and Terms</a>.</p>
        <p>The Website uses cookies to determine whether an End User is active and for other business purposes. The Website may include first-party cookies (i.e., cookies that send data directly to Valinor Pharma, LLC and agents and contractors acting on behalf of Valinor Pharma, LLC, including cookies used to monitor, analyze, and administer the Website), but the Website does not include any third-party cookies (i.e., cookies that send data to our contractors for their commercial use). The cookies on our Website do not track Internet activity across different websites. Since cookies are now used as industry standard, most devices automatically accept cookies, but you can usually change your device’s settings to decline them. If you prefer not to enable cookies, you may choose to disable them; however, please note that certain features on the Website will not be available to you once cookies are disabled.</p>
        <p><span className="bold">(c) Usage Data</span></p>
        <p>Usage Data are collected automatically by the Website’s servers and software. For example, because the Website automatically collects Usage Data for all End Users that visit the Website, your Website session will be tracked. You agree that we may collect and use technical data and related information, including technical information about your device, system and application software, and peripherals, to maintain and support the Website generally.</p>
        <p>Additionally, in some of our email messages, Valinor Pharma, LLC may use a “click-through URL” linked to content on the Website. When an End User clicks onto one of these URLs, the End User will pass through our server before arriving at the destination Web page. Valinor Pharma, LLC tracks this click-through data to help us determine End User interest in certain subject matter and measure the effectiveness of these End User communications. You can avoid being tracked in this way by not clicking text or graphic links in emails from Valinor Pharma, LLC.</p>
        <p>Finally, we may use clear gifs or pixel tags, which are tiny graphic images, in order: (i) to advise us of what parts of the Website End Users have visited, (ii) to measure the effectiveness of any searches End Users perform, and (iii) to enable us to send emails in a format that End Users can read and tell us whether such emails have been opened in order to ensure us that we are sending messages that are of interest to End Users.</p>
        <p><span className="bold">(d) Tracking</span></p>
        <p>Some Web browsers may be configured to send Do Not Track signals to websites, or users may use similar mechanisms, to indicate a user’s preference that certain web technologies not be used to track the user’s online activity. The Website does not accept or process such Do Not Track signals or similar mechanisms.</p>
        <p><span className="bold">3. Use and Sharing of Information by Valinor Pharma, LLC</span></p>
        <p>The End User information that Valinor Pharma, LLC collects may be added to our databases and used for business purposes, including for marketing and promotional purposes, for a statistical analysis of End Users’ behavior, for product development, for content improvement, or to customize the content and layout of the Website. In addition, Valinor Pharma, LLC will, on occasion, send emails to you about the Website or third-party products. If you do not want to receive email from us, visit the “Opt Out” or “Unsubscribe” link at the bottom of any Valinor Pharma, LLC email to remove yourself from continued receipt of such email messages.</p>
        <p>Valinor Pharma, LLC’s policy is not to share the End User information it collects with third parties other than as specified below, or where an End User expressly consents to our sharing of certain information with a third party. We may share End User information with third parties under the following circumstances:</p>
        <p><span className="bold">(a) Business Partners and Vendors</span></p>
        <p>We may use third-party service providers to perform certain business services, and we may disclose Personal Information to such service providers as needed for them to perform these business services. Service providers are only allowed to use, disclose, or retain the Personal Information to provide these services and are prohibited from selling Personal Information. Business services provided may include, but are not limited to, hosting services, communications services, website development services, payment processing services, fulfillment and shipping services, compliance services, quality control services, analytics services, survey and review services, call center and other communication services, and marketing services.</p>
        <p>We may also disclose Personal Information to certain vendors for direct marketing purposes. Under certain circumstances, you may avoid having Valinor Pharma, LLC share your information with its business partners and vendors by not granting Valinor Pharma, LLC permission to share your information. Not granting Valinor Pharma, LLC permission to share your information with its business partners or vendors may limit your access to their services through the Website.</p>
        <p><span className="bold">CALIFORNIA PRIVACY RIGHTS – Shine the Light Disclosures.</span> If you reside in California, you may request certain general information regarding our disclosure of Personal Information during the preceding year to third parties for their direct marketing purposes. To make such a request, please contact us at <a href="mailto:info@valinorrx.com">info@valinorrx.com</a>.</p>
        <p><span className="bold">(b) Business Transfers</span></p>
        <p>As we continue to develop our business, we may buy, sell, or share assets in connection with, for example, a merger, acquisition, reorganization, sale of assets, or bankruptcy. In such transactions, information about End Users generally is often a transferred business asset. In the event that Valinor Pharma, LLC itself or substantially all of Valinor Pharma, LLC’s assets are acquired, information about our End Users may be one of the transferred assets.</p>
        <p><span className="bold">(c) Compliance with Law and Protection of Valinor Pharma, LLC and Others</span></p>
        <p>We may release End User information when we believe, in our sole discretion, that release is appropriate: to comply with the law, including but not limited to, in response to a subpoena served on Valinor Pharma, LLC; to enforce or apply the Agreement, including the Website <Link to="../terms-of-use">Terms of Use</Link> and other applicable agreements, rules, and policies; to protect the rights, property, or safety of Valinor Pharma, LLC, our End Users, or others; or to prevent activity that we believe, in our sole discretion, may be or may become illegal, unethical, or legally actionable (including exchanging End User information with other companies and organizations for fraud protection).</p>
        <p><span className="bold">4. International Transfer</span></p>
        <p>By accessing and using the Website, you acknowledge and agree that Valinor Pharma, LLC controls and operates all parts of the Website from its offices in the United States of America and that the Website is intended for use by End Users located in the United States of America. Unless expressly stated to the contrary, Valinor Pharma, LLC makes no representation that the Website is appropriate or will be available for use in other locations.</p>
        <p>If you access the Website from outside the United States, you acknowledge and agree that your information may be transferred to and maintained on computers and servers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Your consent to the Agreement followed by your submission of such information represents your agreement to the transfer of such information to the United States and the collection, use, and disclosure of your information in accordance with United States law and our Privacy Policy.</p>
        <p><span className="bold">5. Retention of Information and Changing or Deleting Your Information</span></p>
        <p>We will retain the information (including Personal Information) as long as is reasonably necessary to fulfill the purpose for which it was collected, to comply with applicable laws and regulations, and/or to support any claim, defense, or declaration in a case or before a jurisdictional and/or administrative authority, arbitration, or mediation panel. Please contact us if you would like to review, update, correct or delete any Personal Information we may have about you. We may retain an archived copy of your records as required by law or for legitimate business purposes.</p>
        <p><span className="bold">6. Security</span></p>
        <p>We use commercially reasonable security measures, such as encryption, to protect End User information. Please note that no security system is impenetrable. Accordingly, we do not guarantee the security of our databases, nor that information you supply won’t be intercepted while being transmitted to us over the Internet or other network. Any information you transmit to Valinor Pharma, LLC, you do at your own risk. We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored “personal data” (as defined in applicable state statutes on security breach notification) to you via email or conspicuous posting through the Website in the most expedient time possible and without unreasonable delay, as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system.</p>
        <p><span className="bold">7. Children’s Privacy</span></p>
        <p>The Website is intended for adult use only and is not directed towards children, minors, or anyone under the age of 18. If you are under the age of 13, you are not authorized to provide us with any Personal Information. If the parent or guardian of a child under 13 believes that the child has provided us with any Personal Information, the parent or guardian of that child should contact us at the email address below to have this Personal Information deleted from our files.</p>
        <p><span className="bold">8. Contacting Us</span></p>
        <p>If you have any questions about this Privacy Policy, please contact us at:</p>
        <p>Valinor Pharma, LLC<br />
        150 N. Riverside Plaza<br />
        Suite 3480<br />
        Chicago, IL 60606<br />
        <a href="mailto:info@valinorrx.com">info@valinorrx.com</a></p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
